<script setup>
import { onMounted, onBeforeMount, onBeforeUnmount, inject, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { decodeTime } from "@/utils/datetime";
import { 
    getVideoNow, getPlayList, getTeleprogramList, 
    getUpdatePercentage, getGoLive, getNextVideo,
    getPublishVideo, getTimeLive, getCheckTvStatus
} from "./player";
import LiveVideo from "@/components/Video/MainComponent";
import LiveTrack from "@/components/Swiper/LiveTrack";
import LiveChat from "@/components/Chat/LiveChat";

const videoOptions = {
    autoplay: true,
    controls: true,
    liveui: true,
    preload: 'metadata',
    sources: [
        {
            src: `${process.env.VUE_APP_SITE_URL}:8088/hls/stream.m3u8`,
            type: "application/x-mpegURL"
        }
    ],
    controlBar: {
        pictureInPictureToggle: false,
        ProgressControl: false,
    },
    fluid: false,
    language: "ru",
    videoPk: ''
};

const store = useStore();
const router = useRouter();

const teleId = inject('teleId');
const updateTeleId = inject('updateTeleId');

const endOver = ref(false);
const componentKey = ref(false);
const loaderNew = ref(false);
const inLine = ref(0);
const interval = ref(0);
const playList = ref([]);
const indexMax = ref(0);
const indexlive = ref(0);
const timelive = ref("00:00:00");
const startVideo = ref("00:00:00");
const startVideoDef = ref("00:00:00");
const durationOnline = ref(0);
const showOnlineBool = ref(false);
const tv = ref({});
const videoId = ref(0);
const videoInfo = ref({});
const curr_time = ref(0);
const timeOnline = ref(0);
const online = ref(false);

const goLive = getGoLive(online);
const videoNow = getVideoNow({
    indexMax, indexlive, videoId,
    videoInfo, playList, startVideo,
    startVideoDef, durationOnline
});

const fetchPlaylist = getPlayList({
    loaderNew, showOnlineBool, indexMax, playList
}, { videoNow, goLive });

const fetchTeleprogramList = getTeleprogramList({
    tv, indexlive, interval, inLine,
}, { updateTeleId, fetchPlaylist });

const updatePercentage = getUpdatePercentage({ curr_time, timeOnline });

const fetchNextVideo = getNextVideo({
    teleId, indexlive, tv, curr_time, inLine,
    timeOnline, indexMax, endOver,
}, { fetchPlaylist });

const publishVideo = getPublishVideo(videoInfo);

const timeLive = getTimeLive({
    timelive, curr_time, durationOnline, tv, startVideo
}, { updatePercentage, publishVideo, fetchNextVideo });

const checkTvStatus = getCheckTvStatus({ tv, online, endOver });

const goTo = (redirect) => {
    router.push(redirect);
}

localStorage.removeItem('radio');
store.commit('removeRadio');
store.commit('initializeStore');

onBeforeMount(async () => {
    await fetchTeleprogramList();
});

onMounted(async () => {
    await timeLive();

    inLine.value = setInterval(async () => await timeLive(), 2000);
    interval.value = setInterval(async () => await checkTvStatus(), 5000);
});

onBeforeUnmount(() => {
    clearInterval(inLine.value);
    clearInterval(interval.value);
});

const playerActive = computed(() => {
    const hasTv = Object.keys(tv.value || {}).length != 0;
    const hasPlaylist = playList.value.length > 0;
    const hasTvStarted = [1, 4].includes(tv.value.status || 0);

    console.log('playerActive', hasTv, hasPlaylist, showOnlineBool.value, tv.value);

    return hasTv && hasPlaylist && showOnlineBool.value && hasTvStarted;
});
</script>

<template>
    <div class="player" v-if="playerActive">
        <div class="container">
            <section class="defaultStage" v-if="online">
                <LiveVideo 
                    :key="componentKey"
                    :teleId="teleId"
                    :videoInfo="videoInfo"
                    :options="videoOptions"
                    :curr_time="curr_time"
                    :videoId="videoId"
                    :durationInEfir="durationOnline"
                    :timeOfEfir="tv.time_to_start"
                    :timelive="timelive"
                />
            </section>
            <section class="defaultStage in_not_efir" :class="{ 'end-active_efir': endOver, 'wait-efir_block': !loaderNew }" v-else>
                <div v-if="!endOver">
                    <span v-if="loaderNew">
                        <div class="loader">
                            <div class="lds-ring fff_loader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </span>
                    <span v-else>
                        <h3 v-if="startVideo">
                            <i18n-t keypath="teleprogram.live.status.pre.singular" scope="global"></i18n-t> {{ decodeTime(startVideo, $i18n.locale) }}
                        </h3>
                    </span>
                </div>
                <div class="posIn-not_efir" v-else>
                    <i18n-t tag="h3" keypath="teleprogram.live.status.offline.singular" scope="global"></i18n-t>
                </div>
            </section>
            <LiveChat 
                className="player"
                :id="teleId"
                idKey="teleprogram_id"
                chatType="teleprogram"
            />
            <LiveTrack 
                :playList="playList"
                :indexlive="indexlive"
                :timeOnEfir="timeOnline"
                :durationInEfir="durationOnline"
                :currentTime="timelive"
            />
        </div>
    </div>
    <div class="player" v-else></div>
</template>